/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment SpotItem on Spot {\n    budgetDinner {\n      max\n      min\n    }\n    budgetLunch {\n      max\n      min\n    }\n    code\n    latitude\n    longitude\n    name\n    phoneNumber\n    homepageUrl\n    isOpen\n    imakoAdvice\n    crowdedLevel\n    addressfull\n    businessHoursNote\n    smoking\n    seatsText\n    todayBusinessHour {\n      endTime\n      startTime\n    }\n    spotCrowdInformationStatuses {\n      lastCheckedOn\n    }\n    spotCrowdInformations {\n      crowdedMatrix {\n        endTime\n        startTime\n        key\n      }\n      crowdLevel\n    }\n    spotPhotos {\n      imageUrl\n      originalFrom\n      originalUrl\n      isImage\n    }\n  }\n": types.SpotItemFragmentDoc,
    "\n  fragment SpotsItem on Spot {\n    code\n    latitude\n    longitude\n    name\n    isOpen\n    crowdedLevel\n    firstSpotPhoto {\n      imageUrl\n      thumbnailImageUrl\n    }\n  }\n": types.SpotsItemFragmentDoc,
    "\n  query SpotsQuery($bbox: [Float!]) {\n    spots(bbox: $bbox) {\n      ...SpotsItem\n    }\n  }\n": types.SpotsQueryDocument,
    "\n  query Me {\n    me {\n      code\n      userAttribute {\n        id\n      }\n      completeOnboarding\n    }\n  }\n": types.MeDocument,
    "\n  mutation Mutation($input: EnqueteCreateInput!) {\n    enqueteCreate(input: $input) {\n      userAttribute {\n        sex\n        ageGroup\n        nickname\n      }\n    }\n  }\n": types.MutationDocument,
    "\n  mutation CompleteOnboarding(\n    $CompleteOnboardingInput: CompleteOnboardingInput!\n  ) {\n    completeOnboarding(input: $CompleteOnboardingInput) {\n      userOnboarding {\n        id\n        completedAt\n      }\n    }\n  }\n": types.CompleteOnboardingDocument,
    "\n  mutation PushIdtokenCreate($input: PushIdtokenCreateInput!) {\n    pushIdtokenCreate(input: $input) {\n      user {\n        code\n      }\n    }\n  }\n": types.PushIdtokenCreateDocument,
    "\n  mutation PushIdtokenDestroy($input: PushIdtokenDestroyInput!) {\n    pushIdtokenDestroy(input: $input) {\n      user {\n        code\n      }\n    }\n  }\n": types.PushIdtokenDestroyDocument,
    "\n  query SpotQuery($code: String!) {\n    spot(code: $code) {\n      ...SpotItem\n    }\n  }\n": types.SpotQueryDocument,
    "\n  mutation SpotReviewLevelCreate($input: SpotReviewCreateInput!) {\n    spotReviewCreate(input: $input) {\n      spotReview {\n        comment\n        crowdedLevel\n        name\n        visitedOn\n        visitedTime\n      }\n    }\n  }\n": types.SpotReviewLevelCreateDocument,
    "\nmutation SpotInfoRevisionSuggestionCreate($input: SpotInfoRevisionSuggestionCreateInput!) {\n  spotInfoRevisionSuggestionCreate(input: $input) {\n    spotInfoRevisionSuggestion {\n      content\n      createdAt\n      id\n      kind\n      spot {\n        name\n      }\n      updatedAt\n    }\n  }\n}\n": types.SpotInfoRevisionSuggestionCreateDocument,
    "\nmutation SpotReviewDetailCreate($input: SpotReviewCreateInput!) {\n  spotReviewCreate(input: $input) {\n    spotReview {\n      comment\n      crowdedLevel\n      visitedOn\n      visitedTime\n    }\n  }\n}\n": types.SpotReviewDetailCreateDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SpotItem on Spot {\n    budgetDinner {\n      max\n      min\n    }\n    budgetLunch {\n      max\n      min\n    }\n    code\n    latitude\n    longitude\n    name\n    phoneNumber\n    homepageUrl\n    isOpen\n    imakoAdvice\n    crowdedLevel\n    addressfull\n    businessHoursNote\n    smoking\n    seatsText\n    todayBusinessHour {\n      endTime\n      startTime\n    }\n    spotCrowdInformationStatuses {\n      lastCheckedOn\n    }\n    spotCrowdInformations {\n      crowdedMatrix {\n        endTime\n        startTime\n        key\n      }\n      crowdLevel\n    }\n    spotPhotos {\n      imageUrl\n      originalFrom\n      originalUrl\n      isImage\n    }\n  }\n"): (typeof documents)["\n  fragment SpotItem on Spot {\n    budgetDinner {\n      max\n      min\n    }\n    budgetLunch {\n      max\n      min\n    }\n    code\n    latitude\n    longitude\n    name\n    phoneNumber\n    homepageUrl\n    isOpen\n    imakoAdvice\n    crowdedLevel\n    addressfull\n    businessHoursNote\n    smoking\n    seatsText\n    todayBusinessHour {\n      endTime\n      startTime\n    }\n    spotCrowdInformationStatuses {\n      lastCheckedOn\n    }\n    spotCrowdInformations {\n      crowdedMatrix {\n        endTime\n        startTime\n        key\n      }\n      crowdLevel\n    }\n    spotPhotos {\n      imageUrl\n      originalFrom\n      originalUrl\n      isImage\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SpotsItem on Spot {\n    code\n    latitude\n    longitude\n    name\n    isOpen\n    crowdedLevel\n    firstSpotPhoto {\n      imageUrl\n      thumbnailImageUrl\n    }\n  }\n"): (typeof documents)["\n  fragment SpotsItem on Spot {\n    code\n    latitude\n    longitude\n    name\n    isOpen\n    crowdedLevel\n    firstSpotPhoto {\n      imageUrl\n      thumbnailImageUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SpotsQuery($bbox: [Float!]) {\n    spots(bbox: $bbox) {\n      ...SpotsItem\n    }\n  }\n"): (typeof documents)["\n  query SpotsQuery($bbox: [Float!]) {\n    spots(bbox: $bbox) {\n      ...SpotsItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Me {\n    me {\n      code\n      userAttribute {\n        id\n      }\n      completeOnboarding\n    }\n  }\n"): (typeof documents)["\n  query Me {\n    me {\n      code\n      userAttribute {\n        id\n      }\n      completeOnboarding\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Mutation($input: EnqueteCreateInput!) {\n    enqueteCreate(input: $input) {\n      userAttribute {\n        sex\n        ageGroup\n        nickname\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation Mutation($input: EnqueteCreateInput!) {\n    enqueteCreate(input: $input) {\n      userAttribute {\n        sex\n        ageGroup\n        nickname\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CompleteOnboarding(\n    $CompleteOnboardingInput: CompleteOnboardingInput!\n  ) {\n    completeOnboarding(input: $CompleteOnboardingInput) {\n      userOnboarding {\n        id\n        completedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CompleteOnboarding(\n    $CompleteOnboardingInput: CompleteOnboardingInput!\n  ) {\n    completeOnboarding(input: $CompleteOnboardingInput) {\n      userOnboarding {\n        id\n        completedAt\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation PushIdtokenCreate($input: PushIdtokenCreateInput!) {\n    pushIdtokenCreate(input: $input) {\n      user {\n        code\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation PushIdtokenCreate($input: PushIdtokenCreateInput!) {\n    pushIdtokenCreate(input: $input) {\n      user {\n        code\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation PushIdtokenDestroy($input: PushIdtokenDestroyInput!) {\n    pushIdtokenDestroy(input: $input) {\n      user {\n        code\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation PushIdtokenDestroy($input: PushIdtokenDestroyInput!) {\n    pushIdtokenDestroy(input: $input) {\n      user {\n        code\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SpotQuery($code: String!) {\n    spot(code: $code) {\n      ...SpotItem\n    }\n  }\n"): (typeof documents)["\n  query SpotQuery($code: String!) {\n    spot(code: $code) {\n      ...SpotItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SpotReviewLevelCreate($input: SpotReviewCreateInput!) {\n    spotReviewCreate(input: $input) {\n      spotReview {\n        comment\n        crowdedLevel\n        name\n        visitedOn\n        visitedTime\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SpotReviewLevelCreate($input: SpotReviewCreateInput!) {\n    spotReviewCreate(input: $input) {\n      spotReview {\n        comment\n        crowdedLevel\n        name\n        visitedOn\n        visitedTime\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation SpotInfoRevisionSuggestionCreate($input: SpotInfoRevisionSuggestionCreateInput!) {\n  spotInfoRevisionSuggestionCreate(input: $input) {\n    spotInfoRevisionSuggestion {\n      content\n      createdAt\n      id\n      kind\n      spot {\n        name\n      }\n      updatedAt\n    }\n  }\n}\n"): (typeof documents)["\nmutation SpotInfoRevisionSuggestionCreate($input: SpotInfoRevisionSuggestionCreateInput!) {\n  spotInfoRevisionSuggestionCreate(input: $input) {\n    spotInfoRevisionSuggestion {\n      content\n      createdAt\n      id\n      kind\n      spot {\n        name\n      }\n      updatedAt\n    }\n  }\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation SpotReviewDetailCreate($input: SpotReviewCreateInput!) {\n  spotReviewCreate(input: $input) {\n    spotReview {\n      comment\n      crowdedLevel\n      visitedOn\n      visitedTime\n    }\n  }\n}\n"): (typeof documents)["\nmutation SpotReviewDetailCreate($input: SpotReviewCreateInput!) {\n  spotReviewCreate(input: $input) {\n    spotReview {\n      comment\n      crowdedLevel\n      visitedOn\n      visitedTime\n    }\n  }\n}\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;