import ReactDOM from 'react-dom/client'
import { SpotHolder, SpotHolderLabel } from '../SpotHolder'
import { NavigateFunction } from 'react-router-dom'

type ClusterMarkersInfo = {
  name: string
  code: string
  imageUrl: string
  crowdedLevel: string
}
type Props = React.PropsWithChildren<{
  showTitle: boolean
  navigate: NavigateFunction
  clusterMarkersInfo: ClusterMarkersInfo[]
}>

function SpotClusterMarker({ clusterMarkersInfo, showTitle }: Props) {
  return (
    <>
      <SpotHolder
        crowdedLevel={clusterMarkersInfo[0].crowdedLevel}
        imageUrl={clusterMarkersInfo[0].imageUrl}
        highlight={false}
        markerCount={clusterMarkersInfo.length}
      />
      {showTitle && (
        <SpotHolderLabel text={clusterMarkersInfo[0].name} highlight={false} />
      )}
    </>
  )
}

function SpotClusterInfoWindow({ clusterMarkersInfo, navigate }: Props) {
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const code = e.currentTarget.getAttribute('data-code')
    navigate(`/spots/${code}`)
    e.stopPropagation()
  }
  return (
    <>
      <div className="text-center font-bold">
        店舗が{clusterMarkersInfo.length}件あります
      </div>
      {clusterMarkersInfo.map((markerInfo, index) => (
        <div key={index}>
          <button onClick={onClick} data-code={markerInfo.code} className="border border-1 border-gray-300 w-full rounded-lg p-1 my-1 hover:bg-gray-100">
            <div className="flex gap-3 items-center">
              <SpotHolder
                crowdedLevel={markerInfo.crowdedLevel}
                imageUrl={markerInfo.imageUrl}
                highlight={false}
                withArrow={false}
              />
              <div className="flex-1 align-middle">
                {markerInfo.name.length > 30
                  ? markerInfo.name.slice(0, 30) + '...'
                  : markerInfo.name}
              </div>
            </div>
          </button>
        </div>
      ))}
    </>
  )
}

type ElementOpts = {
  position: google.maps.LatLng
}

export function SpotClusterMarkerElement(
  props: Props & ElementOpts,
  container: Element,
  map: google.maps.Map,
) {
  container.innerHTML = ''
  const root = ReactDOM.createRoot(container)
  root.render(<SpotClusterMarker {...props} />)
  const options = {
    map,
    content: container,
    position: props.position,
    title: `${props.clusterMarkersInfo[0].name} 他${props.clusterMarkersInfo.length}件`,
  }
  const marker = new google.maps.marker.AdvancedMarkerElement(options)
  const zoom = map.getZoom() ?? 0
  if (zoom > 21) {
    const infowindowContainer = document.createElement('div')
    const infowindowRoot = ReactDOM.createRoot(infowindowContainer)

    infowindowRoot.render(<SpotClusterInfoWindow {...props} />)
    const infowindow = new google.maps.InfoWindow({
      content: infowindowContainer,
      ariaLabel: 'Uluru',
    })
    marker.addListener('click', () => {
      infowindow.open({
        anchor: marker,
        map,
      })
    })
  }
  return marker
}
