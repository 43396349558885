import { APIProvider } from '@vis.gl/react-google-maps'
import { Spinner } from 'flowbite-react'
import { ImakoMap } from '../ImakoMap'
import { SpotsQueryQuery } from '../../gql/graphql'
import { Latlng } from '../../models/PositionType'

export function MapContainer({
  loading,
  data,
  defaultCenter,
  currentPosition,
  currentHeading,
  reloadFunc,
  onMoveCenter,
  onReloadCurrentTime,
  children
}: {
  loading: boolean
  defaultCenter?: string | null,
  data: SpotsQueryQuery | undefined
  currentPosition: Latlng | undefined
  currentHeading: number
  reloadFunc: (cache?: boolean) => void
  onMoveCenter?: () => void
  onReloadCurrentTime?: () => void,
  children: React.ReactNode
}) {
  return (
    <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY ?? ''}>
      {loading && (
        <div
          className={`bg-gray-400/50 absolute top-50 left-0 w-full h-full z-10 overflow-y-visible content-center text-center`}
        >
          <Spinner
            aria-label="Default status example"
            size="xl"
            className="z-20"
            color="success"
          />
        </div>
      )}
      <ImakoMap
        data={data}
        defaultCenter={defaultCenter}
        currentPosition={currentPosition}
        currentHeading={currentHeading}
        reloadFunc={reloadFunc}
        onMoveCenter={onMoveCenter}
        onReloadCurrentTime={onReloadCurrentTime}
      />
      {children}
    </APIProvider>
  )
}
