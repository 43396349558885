import {
  AdvancedMarker,
  CollisionBehavior,
  useMapsLibrary,
} from '@vis.gl/react-google-maps'
import { SpotHolder, SpotHolderLabel } from '../SpotHolder'
import { useNavigate, useParams } from 'react-router-dom'
import { FragmentType, graphql, useFragment } from '~/gql'
import { Marker } from '@googlemaps/markerclusterer'
import { useCallback, useEffect, useState } from 'react'

export const SpotsFragment = graphql(`
  fragment SpotsItem on Spot {
    code
    latitude
    longitude
    name
    isOpen
    crowdedLevel
    firstSpotPhoto {
      imageUrl
      thumbnailImageUrl
    }
  }
`)

type Props = React.PropsWithChildren<{
  spotData: FragmentType<typeof SpotsFragment>
  showTitle: boolean
  collisionBehavior: CollisionBehavior | undefined
  debounceBounds: google.maps.LatLngBoundsLiteral | null
  setMarkerRef: (marker: Marker | null, key: string) => void
}>

export function SpotMarker({
  spotData,
  showTitle,
  collisionBehavior,
  setMarkerRef,
  debounceBounds,
}: Props) {
  const { spotCode } = useParams()
  const navigate = useNavigate()
  const spot = useFragment(SpotsFragment, spotData)
  const coreLib = useMapsLibrary('core')
  const [visible, setVisible] = useState(false)

  const onClick = () => {
    const code = spot?.code
    navigate(`/spots/${code}`)
  }
  const ref = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement) => {
      // marker にユーザデータをもたせる苦肉の策
      if (marker?.content) {
        const content = marker.content as HTMLElement
        content.dataset.imageUrl = spot.firstSpotPhoto?.imageUrl ?? ''
        content.dataset.code = spot.code
        content.dataset.crowdedLevel = spot.isOpen
          ? spot.crowdedLevel
          : 'noBusinessHours'
        content.dataset.name = spot.name
      }

      return setMarkerRef(marker, spot.code)
    },
    [setMarkerRef, spot],
  )
  const position = { lat: spot.latitude ?? 0, lng: spot.longitude ?? 0 }

  useEffect(() => {
    if (!coreLib) return
    if (!debounceBounds) return
    const bounds = new coreLib.LatLngBounds(debounceBounds)
    setVisible(bounds.contains(position))
  }, [coreLib, debounceBounds, position])

  const highlight = spotCode == spot.code

  if (!visible) return

  return (
    <>
      <AdvancedMarker
        className="relative"
        position={position}
        collisionBehavior={collisionBehavior}
        title={spot.name}
        onClick={onClick}
        ref={ref}
        zIndex={highlight ? 10 : 1}
      >
        <SpotHolder
          crowdedLevel={spot.isOpen ? spot.crowdedLevel : 'noBusinessHours'}
          imageUrl={spot.firstSpotPhoto?.thumbnailImageUrl ?? ''}
          highlight={highlight}
        />
        {showTitle && (
          <SpotHolderLabel text={spot.name} highlight={highlight} />
        )}
      </AdvancedMarker>
    </>
  )
}
