import {
  Map,
  useMap,
  MapControl,
  ControlPosition,
  MapCameraChangedEvent,
} from '@vis.gl/react-google-maps'
import { useDebounce } from '@uidotdev/usehooks'
import { useCallback, useEffect, useState } from 'react'
import { SpotsQueryQuery } from '~/gql/graphql'
import { useNavigate } from 'react-router-dom'
import { Button } from 'flowbite-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons'
import { GINZA_CENTER } from '~/utils/const'
import { Latlng } from '~/models/PositionType'
import { ClusteredSpotMarkers } from '../SpotMarker/ClusteredSpotMarkers'
import { CurrentPositionMarker } from '../CurrentPositionMarker'

type Props = {
  initialZoom?: number
  data: SpotsQueryQuery | undefined
  defaultCenter?: Latlng | string | null
  currentPosition: Latlng | undefined
  currentHeading: number
  reloadFunc: (cache?: boolean) => void
  onMoveCenter?: () => void
  onReloadCurrentTime?: () => void
}

export function ImakoMap({
  initialZoom = 19,
  data,
  defaultCenter,
  currentPosition,
  currentHeading,
  reloadFunc,
  onMoveCenter,
  onReloadCurrentTime,
}: Props): JSX.Element {
  const mapId = import.meta.env.VITE_GOOGLE_MAPS_ID
  const map = useMap()
  const [showTitle, setShowTitle] = useState(false)
  const navigate = useNavigate()
  const [bounds, setBounds] = useState<google.maps.LatLngBoundsLiteral | null>(
    null,
  )
  const debounceBounds = useDebounce(bounds, 100)

  const moveCenter = useCallback(() => {
    if (!currentPosition) return
    map?.setCenter(currentPosition)
    if (onMoveCenter) onMoveCenter()
  }, [currentPosition, map, onMoveCenter])

  const handleCameraChanged = useCallback(
    (ev: MapCameraChangedEvent) => {
      setShowTitle(ev.detail.zoom > 18)
    },
    [setShowTitle, map],
  )

  const onReloadButtonClick = useCallback(() => {
    reloadFunc(false)
    if (onReloadCurrentTime) onReloadCurrentTime()
  }, [onReloadCurrentTime])

  const handleBoundsChanged = useCallback(
    (e: MapCameraChangedEvent) => {
      setBounds(e.detail.bounds)
    },
    [setBounds],
  )

  const handleClick = useCallback(() => {
    navigate('/')
  }, [navigate])

  useEffect(() => {
    if (!map) return
    if (!defaultCenter) return

    if (typeof defaultCenter === 'string') {
      const [lat, lng] = defaultCenter.split(',')
      if (lat && lng) map.panTo({ lat: parseFloat(lat), lng: parseFloat(lng) })
    } else {
      map.panTo(defaultCenter)
    }
  }, [map, defaultCenter])

  return (
    <Map
      defaultCenter={GINZA_CENTER}
      defaultZoom={initialZoom}
      disableDefaultUI={true}
      minZoom={12}
      mapId={mapId}
      clickableIcons={false}
      gestureHandling="greedy"
      headingInteractionEnabled={true}
      onCameraChanged={handleCameraChanged}
      onBoundsChanged={handleBoundsChanged}
      onClick={handleClick}
    >
      {currentPosition && (
        <CurrentPositionMarker
          currentPosition={currentPosition}
          currentHeading={currentHeading}
        />
      )}

      <MapControl position={ControlPosition.TOP_CENTER}>
        <Button
          color="gray"
          className="xw-fit h-6 border-0 p-0 mt-12 rounded-full drop-shadow-xl flex items-center justify-center"
          onClick={onReloadButtonClick}
        >
          <span className="text-xs">現在時刻で再取得する</span>
        </Button>
      </MapControl>

      <MapControl position={ControlPosition.RIGHT_BOTTOM}>
        <Button
          color="gray"
          className="ansolute w-10 h-10 bottom-40 right-5 rounded-full drop-shadow-xl"
          onClick={moveCenter}
        >
          <FontAwesomeIcon icon={faLocationCrosshairs} />
        </Button>
      </MapControl>

      {data?.spots ? (
        <ClusteredSpotMarkers
          spots={data.spots}
          showTitle={showTitle}
          debounceBounds={debounceBounds}
        />
      ) : null}
    </Map>
  )
}
