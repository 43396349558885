import {
  QueryRef,
  useLazyQuery,
  useMutation,
  useReadQuery,
} from '@apollo/client'
import { graphql } from '~/gql'
import { ToastContainer } from 'react-toastify'
import { MapContainer } from '../components/MapContainer'
import { FirstEnquete } from '../components/FirstEnquete'
import { Outlet, useLoaderData, useSearchParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { Header } from '../components/Header'
import { Latlng } from '../models/PositionType'
import { preloadQuery } from '../utils/apiClient'
import {
  firebaseAnalytics,
  firebaseGetToken,
  waitUser,
} from '../utils/firebase'
import { Onboarding } from '../components/Onboarding'

import './root.scss'
import 'react-toastify/dist/ReactToastify.css'
import { MeQuery } from '../gql/graphql'
import { setUserId, logEvent } from 'firebase/analytics'
import { NotificationModal } from '../components/NotificationModal'

const spotsDocument = graphql(`
  query SpotsQuery($bbox: [Float!]) {
    spots(bbox: $bbox) {
      ...SpotsItem
    }
  }
`)

const userDocument = graphql(`
  query Me {
    me {
      code
      userAttribute {
        id
      }
      completeOnboarding
    }
  }
`)

const userAttributeMutationDocument = graphql(`
  mutation Mutation($input: EnqueteCreateInput!) {
    enqueteCreate(input: $input) {
      userAttribute {
        sex
        ageGroup
        nickname
      }
    }
  }
`)

const completeOnboardingMutationDocument = graphql(`
  mutation CompleteOnboarding(
    $CompleteOnboardingInput: CompleteOnboardingInput!
  ) {
    completeOnboarding(input: $CompleteOnboardingInput) {
      userOnboarding {
        id
        completedAt
      }
    }
  }
`)

const pushIdtokenCreateMutationDocument = graphql(`
  mutation PushIdtokenCreate($input: PushIdtokenCreateInput!) {
    pushIdtokenCreate(input: $input) {
      user {
        code
      }
    }
  }
`)

const pushIdtokenDestroyMutationDocument = graphql(`
  mutation PushIdtokenDestroy($input: PushIdtokenDestroyInput!) {
    pushIdtokenDestroy(input: $input) {
      user {
        code
      }
    }
  }
`)

// https://stackoverflow.com/questions/60640018/devicemotionevent-request-permission-with-typescript
interface DeviceOrientationEventiOS extends DeviceOrientationEvent {
  requestPermission?: () => Promise<'granted' | 'denied'>
}

export async function loader() {
  await waitUser
  return preloadQuery(userDocument).toPromise()
}

function Root() {
  const loaderQueryRef = useLoaderData() as QueryRef<MeQuery, unknown>
  const { data: loadData } = useReadQuery(loaderQueryRef)
  const [getSpots, { loading, data }] = useLazyQuery(spotsDocument)
  const [createEnquete] = useMutation(userAttributeMutationDocument)
  const [completeOnboarding] = useMutation(completeOnboardingMutationDocument)
  const [createPushIdtoken] = useMutation(pushIdtokenCreateMutationDocument)
  const [destroyPushIdtoken] = useMutation(pushIdtokenDestroyMutationDocument)
  const [searchParams] = useSearchParams()
  const [showFirstEnquete, setShowFirstEnquete] = useState(false)

  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const [currentPosition, setCurrentPosition] = useState<Latlng | undefined>()
  const [currentHeading, setCurrentHeading] = useState<number>(0)
  const reloadFunc = useCallback(
    (cache = true) => {
      getSpots({
        variables: {},
        fetchPolicy: cache ? 'cache-first' : 'no-cache',
      })
    },
    [getSpots],
  )

  const initGeo = () => {
    if ('geolocation' in navigator) {
      return navigator.geolocation.watchPosition(
        (position) => {
          setCurrentPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
          // to GA
          logEvent(firebaseAnalytics, 'geolocation', {
            geolocationAvailable: true,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        },
        (error) => {
          console.error(error)
          logEvent(firebaseAnalytics, 'geolocation', {
            geolocationAvailable: false,
            error: error.message,
          })
        },
        { maximumAge: 0, timeout: 10000, enableHighAccuracy: false },
      )
    }
  }

  const registToken = (token: string) => {
    const lastToken = localStorage.getItem('imako-push-token')
    const lastFetchGetTokenAt = localStorage.getItem(
      'imako-last-fetch-get-token-at',
    )
    if (
      lastToken === token &&
      lastFetchGetTokenAt &&
      Date.now() - parseInt(lastFetchGetTokenAt) < 1000 * 60 * 60 * 24 * 30
    )
      return

    createPushIdtoken({ variables: { input: { input: { token } } } })
    localStorage.setItem('imako-last-fetch-get-token-at', Date.now().toString())
    localStorage.setItem('imako-push-token', token)
  }

  const unregistToken = () => {
    const lastToken = localStorage.getItem('imako-push-token')
    if (!lastToken) return

    destroyPushIdtoken({ variables: { input: {} } })
    localStorage.removeItem('imako-last-fetch-get-token-at')
    localStorage.removeItem('imako-push-token')
  }

  const requestNotificationPermission = () => {
    const lastToken = localStorage.getItem('imako-push-token')
    const lastFetchGetTokenAt = localStorage.getItem(
      'imako-last-fetch-get-token-at',
    )
    if (
      lastToken &&
      lastFetchGetTokenAt &&
      Date.now() - parseInt(lastFetchGetTokenAt) < 1000 * 60 * 60 * 24 * 30
    )
      return
    setShowNotificationModal(true)
    localStorage.setItem(
      'imamko-request-notification-permission-at',
      Date.now().toString(),
    )
  }

  const handleNotificationCancel = () => {
    localStorage.setItem(
      'imako-request-notification-permission-result',
      'false',
    )
  }

  const getNotificationToken = async () => {
    try {
      const token = await firebaseGetToken()
      if (token) {
        return setTimeout(() => {
          registToken(token)
        }, 1000)
      }
      throw new Error('token is empty')
    } catch (_error) {
      localStorage.setItem(
        'imako-request-notification-permission-result',
        'denied',
      )
      return setTimeout(() => {
        unregistToken()
      }, 1000)
    }
  }

  const handleNotificationOk = () => {
    ;(async () => {
      const permission = await Notification.requestPermission()
      localStorage.setItem(
        'imako-request-notification-permission-result',
        permission,
      )

      if (permission === 'granted') {
        return await getNotificationToken()
      } else {
        return setTimeout(() => {
          unregistToken()
        }, 1000)
      }
    })()
  }

  const initNotification = () => {
    if (import.meta.env.VITE_ENABLE_PUSH_NOTIFICATION !== 'yes') return

    const lastPermissionAt = localStorage.getItem(
      'imamko-request-notification-permission-at',
    )
    const lastPermission =
      localStorage.getItem('imako-request-notification-permission-result') ??
      'false'

    if (
      lastPermission === 'false' &&
      (!lastPermissionAt || Date.now() - parseInt(lastPermissionAt) > 3) // 1000 * 60 * 60 * 24 * 30)
    ) {
      requestNotificationPermission()
    } else if (lastPermission === 'granted') {
      ;(async () => {
        return getNotificationToken()
      })()
    } else if (lastPermission === 'denied') {
      // 意図しない場合もあるので、リクエストを希求してもよさそう
      // eg) safariの通知許可要求の変更
      // https://support.apple.com/ja-jp/guide/safari/sfri40734/mac
    }
  }

  const removeGeo = (id: number) => {
    if (!id) return
    navigator.geolocation.clearWatch(id)
  }

  const initDeviceOrientation = async () => {
    if (DeviceOrientationEvent as unknown as DeviceOrientationEventiOS) {
      let status = 'granted'
      const DevicerientationEventKlass =
        DeviceOrientationEvent as unknown as DeviceOrientationEventiOS
      if (typeof DevicerientationEventKlass.requestPermission === 'function') {
        status = await DevicerientationEventKlass.requestPermission()
        logEvent(firebaseAnalytics, 'deviceorientationpermission', {
          status: status,
        })
      }
      if (status === 'granted') {
        watchDeviceOrientation()
      }
    }
  }
  const watchDeviceOrientation = () => {
    removeDeviceOrientation()
    window.addEventListener('deviceorientation', handleDeviceOrientation)
  }
  const removeDeviceOrientation = () => {
    window.removeEventListener('deviceorientation', handleDeviceOrientation)
  }
  const handleDeviceOrientation = (event: DeviceOrientationEvent) => {
    setCurrentHeading(event.alpha ?? 0)
  }

  useEffect(() => {
    reloadFunc()
  }, [reloadFunc])

  // アンケートは表示しない
  // useEffect(() => {
  //   setShowFirstEnquete(!loadData.me?.userAttribute)
  // }, [loadData])

  useEffect(() => {
    if (!loadData.me?.code) return

    setUserId(firebaseAnalytics, loadData.me?.code)
    return () => {
      setUserId(firebaseAnalytics, null)
    }
  }, [loadData])

  const isCompleteOnboarding = loadData.me?.completeOnboarding

  useEffect(() => {
    if (!isCompleteOnboarding) return
    const watchId = initGeo()
    watchDeviceOrientation()

    return () => {
      if (watchId) removeGeo(watchId)
    }
  }, [isCompleteOnboarding])

  const handleOnboardingStart = () => {
    completeOnboarding({ variables: { CompleteOnboardingInput: {} } })
    initGeo()
    initDeviceOrientation()
    initNotification()
  }

  const onMoveCenter = () => {
    logEvent(firebaseAnalytics, 'move_center', {})
    // もし初回オンボーディング経過後にとれなかったら再度取得する
    initDeviceOrientation()
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        theme="light"
        hideProgressBar
      />
      <Header />
      <MapContainer
        loading={loading}
        defaultCenter={searchParams.get('c')}
        data={data}
        reloadFunc={reloadFunc}
        currentPosition={currentPosition}
        currentHeading={currentHeading}
        onMoveCenter={onMoveCenter}
        onReloadCurrentTime={() =>
          logEvent(firebaseAnalytics, 'reload_current_time', {})
        }
      >
        <Outlet context={{ position: currentPosition }} />
      </MapContainer>
      {showFirstEnquete && (
        <FirstEnquete
          onSubmit={(data) => {
            createEnquete({ variables: { input: { inputs: data } } })
            setShowFirstEnquete(false)
          }}
        />
      )}
      {!showFirstEnquete && !loadData.me?.completeOnboarding && (
        <Onboarding onStart={handleOnboardingStart} />
      )}
      <NotificationModal
        onOk={handleNotificationOk}
        onCancel={handleNotificationCancel}
        showModal={showNotificationModal}
      />
    </>
  )
}

export default Root
