import { Outlet, useOutletContext, useParams } from 'react-router-dom'
import { DetailPanel } from '../components/DetailPanel'
import { graphql } from '../gql'
import { useMutation, useQuery } from '@apollo/client'
import { Spinner } from 'flowbite-react'
import { format } from 'date-fns'
import { useEffect } from 'react'
import { logEvent } from 'firebase/analytics'
import { firebaseAnalytics } from '../utils/firebase'
import { getRouteUrl } from '~/utils/googleMap'
import { Latlng } from '~/models/PositionType'

const spotDocument = graphql(`
  query SpotQuery($code: String!) {
    spot(code: $code) {
      ...SpotItem
    }
  }
`)

const reviewMutation = graphql(`
  mutation SpotReviewLevelCreate($input: SpotReviewCreateInput!) {
    spotReviewCreate(input: $input) {
      spotReview {
        comment
        crowdedLevel
        name
        visitedOn
        visitedTime
      }
    }
  }
`)

function Spot() {
  const params = useParams()
  const { data, loading } = useQuery(spotDocument, {
    variables: { code: params.spotCode || '' },
  })
  const [createReview] = useMutation(reviewMutation)
  const { position } = useOutletContext<{
    position: { lat: number; lng: number }
  }>()

  function getVisitedTime(now: Date) {
    const hour = now.getHours()
    if (hour >= 22 && hour < 5) {
      return 6
    } else if (hour < 12) {
      return 1
    } else if (hour < 14) {
      return 2
    } else if (hour < 16) {
      return 3
    } else if (hour < 18) {
      return 4
    } else {
      return 5
    }
  }

  useEffect(() => {
    logEvent(firebaseAnalytics, 'select_content', {
      content_type: 'spot',
      item_id: params.spotCode,
    })
  }, [data])

  const onSubmit = (d: { crowdedLevel: string }) => {
    const now = new Date()

    createReview({
      variables: {
        input: {
          spotReviewInput: {
            spotCode: params.spotCode || '',
            name: 'dummy',
            comment: '',
            visitedOn: format(now, 'yyyy-MM-dd'),
            visitedTime: getVisitedTime(now),
            crowdedLevel: d.crowdedLevel,
            reviewType: 'crowded_level',
          },
        },
      },
    })
  }

  const onShare = () => {
    logEvent(firebaseAnalytics, 'share', {
      content_type: 'spot',
      item_id: params.spotCode,
    })
  }

  const onNavigation = (from: Latlng, to: Latlng) => {
    logEvent(firebaseAnalytics, 'navigation', {
      item_id: params.spotCode,
    })
    const routeUrl = getRouteUrl(from, to)
    window.location.replace(routeUrl)
  }

  const onPhone = () => {
    logEvent(firebaseAnalytics, 'phone', {
      item_id: params.spotCode,
    })
  }

  return (
    <>
      <div className="absolute md:w-96 md:h-full md:top-10 md:rounded-r-lg md:shadow-xl w-full left-0 bottom-0 h-3/4 bg-white delay-75 rounded-t-lg overflow-y-auto">
        {loading || !data ? (
          <Spinner />
        ) : (
          <DetailPanel
            onSubmit={onSubmit}
            onShare={onShare}
            onNavigation={onNavigation}
            onPhone={onPhone}
            position={position}
            spotFragment={data.spot}
          />
        )}
      </div>
      <Outlet />
    </>
  )
}

export default Spot
